import React, { useState } from "react";
import { BellIcon, XIcon } from "@heroicons/react/outline";

const Alert = ({ type, message }) => {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <div
        className={`container ${
          type === "success" ? "bg-blue-500" : "bg-red-500"
        } flex items-center rounded-md text-white text-md font-medium px-4 my-5 py-3 relative transition duration-500 ease-in-out animate-pulse`}
        role="alert"
      >
        <BellIcon class="w-4 h-4 mr-2" />
        <p>{message}</p>
        <button
          onClick={() => setVisible(false)}
          className="absolute top-0 bottom-0 right-0 px-4 py-3"
        >
          <XIcon class="h-6 w-6 text-white" />
        </button>
      </div>
    )
  );
};

export default Alert;
