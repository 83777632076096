import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Academics,
  PersonalInfo,
  SoftwareDevelopment,
  Done,
} from "../../components/forms/index";
import Alert from "../../components/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import { API } from "../../utils";
import { useLocalStorageState } from "../../utils";
export default function Forms() {
  const [initialValues, handleUpdateForm] = useLocalStorageState(
    {
      key: "application", 
      value: {
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        semester: "",
        major: "",
        minor: "",
        howDidYouHear: "",
        website: "",
        technologies: "",
        projects: "",
        interests: "",
        processDisagreement: "",
        projectDetails: "",
        acronym: "",
        struggle: "",
        morale: "",
        meetingslotone: "",
        meetingslottwo: "",
        meetingslotthree: "",
        feedback: "",
      }
    });
  const [step, setStep] = useState(0);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [submitted, hasSubmitted] = useState(false);
  const [error, isError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const recaptchaRef = useRef();
  const ApplicationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    country: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    semester: Yup.string().required("Required"),
    major: Yup.string().required("Required"),
    minor: Yup.string().required("Required"),
    howDidYouHear: Yup.string().required("Required"),
    technologies: Yup.string().min(20, "Too Short!").required("Required"),
    projects: Yup.string().min(20, "Too Short!").required("Required"),
    meetingslotone: Yup.string().required("Required"),
    meetingslottwo: Yup.string().required("Required"),
    meetingslotthree: Yup.string().required("Required"),
  });

  const handleClick = (direction) => {
    if (direction === "next" && step < steps.length - 1) setStep(step + 1);
    if (direction === "previous" && step > 0) setStep(step - 1);
  };

  const steps = [
    {
      name: "Step 1: Personal Information",
      description: "Personal Information",
      subtitle: "We would love to know you! Tell us about yourself.",
    },
    {
      name: "Step 2: Academics",
      description: "Academic Background & Job Interest",
      subtitle: "Your academic career and coursework.",
    },
    {
      name: "Step 3: Development Background",
      description: "Software Development",
      subtitle: "About your knowledge and previous experiences.",
    },
    {
      name: "Step 4: That's it!",
      description: "Final Remarks",
      subtitle: "You finally made it to the end, congratulations!",
    },
  ];
  return (
    <section className="container mx-auto py-auto mb-20" id="form">
      <div className="p-4 space-y-2 bg-coolGray-100 text-coolGray-800">
        <h3 className="md:text-left text-center font-semibold">
          {steps[step].name}
        </h3>
        <div className="flex justify-center md:block">
          <div className="flex max-w-xs space-x-3">
            {steps.map((item, index) => {
              let color =
                index === step
                  ? "bg-gray-800"
                  : index < step
                  ? "bg-blue-600"
                  : index > step
                  ? "bg-gray-400"
                  : null;
              return (
                <span
                  key={item.name}
                  className={`w-12 h-2 rounded-sm ${color}`}
                ></span>
              );
            })}
          </div>
        </div>
      </div>

      {!submitted && (
        <div className="mt-10 sm:mt-0 p-4">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-2xl font-medium leading-6 text-gray-900">
                  {steps[step].description}
                </h3>
                <p className="mt-1 text-md text-gray-600">
                  {steps[step].subtitle}
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={ async (values) => {
                    setSubmitting(true);
                    const token = await recaptchaRef.current.executeAsync();
                    setProgress(0);
                    const body = new FormData();
                    body.append('files.transcript', file, `${values.lastName.toLowerCase()}_${values.firstName.toLowerCase()}_Transcript.pdf`);
                    const constructed = {
                      ...values,
                      token: token,
                    };
                    body.append('data', JSON.stringify(constructed));
                    API
                      .post("applicants", body, {
                        onUploadProgress: (progressEvent) =>
                          setProgress(
                            ((progressEvent.loaded / progressEvent.total) *
                              100)
                          ),
                      })
                      .then((res) => {
                        hasSubmitted(true);
                        isError(false);
                        if(localStorage.getItem('application')) localStorage.removeItem('application');
                      })
                      .catch((err) => {
                        isError(true);
                        console.error(err);
                      }).finally(() => setSubmitting(false));
                  }}
                  validationSchema={ApplicationSchema}
                >
                  {({ values, errors, touched, isValid }) => (
                    <Form>
                      {step === 0 && (
                        <PersonalInfo
                          save={handleUpdateForm}
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                      )}
                      {step === 1 && (
                        <Academics
                          save={handleUpdateForm}
                          values={values}
                          file={file}
                          setFile={setFile}
                          errors={errors}
                          touched={touched}
                        />
                      )}
                      {step === 2 && (
                        <SoftwareDevelopment
                          save={handleUpdateForm}
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                      )}
                      {step === 3 && (
                        <Done
                          values={values}
                          save={handleUpdateForm}
                          errors={errors}
                          touched={touched}
                        />
                      )}
                      {step === 0 && <p className="block ml-5 text-sm font-medium text-green-600">Note: Your application details will be saved locally as you go.</p>}
                      {!(isValid && file) &&
                      step === steps.length - 1 ? (
                        <div className="px-6 block text-sm font-medium text-red-600">
                          You can submit once all **required** fields are set
                        </div>
                      ) : null}
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        {step > 0 && (
                          <button
                            type="button"
                            onClick={() => handleClick("previous")}
                            className="inline-flex justify-center mx-1 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-red-700 focus:outline-none"
                          >
                            Previous
                          </button>
                        )}
                        {step < steps.length - 1 && (
                          <button
                            type="button"
                            onClick={() => handleClick("next")}
                            className="inline-flex justify-center mx-1 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary hover:text-black focus:outline-none"
                          >
                            Next
                          </button>
                        )}
                        {step === steps.length - 1 && (
                          <button
                            disabled={!(isValid && file)}
                            type="submit"
                            className={`inline-flex justify-center mx-1 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none text-white ${
                              !(isValid && file)
                                ? "bg-gray-500 cursor-not-allowed"
                                : "bg-red-600 hover:bg-red-700"
                            }`}
                          >
                            Submit
                          </button>
                        )}
                        {isValid && file && submitting && (
                          <div className="relative pt-5">
                            <div className="overflow-hidden h-2 text-xs flex rounded bg-purple-200">
                              <div
                                style={{ width: progress + "%" }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
      {submitted && (
        <Alert
          type="success"
          message="Your Application has been sent! Expect a response in the next few days :)"
        />
      )}
      {error && (
        <Alert
          type="error"
          message="There was an issue sending your request, please try again or contact us at neocedrus@aui.ma"
        />
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Leu7NccAAAAANUr1L9r06T5HuwVdXdw0ek7GUBL"
      />
    </section>
  );
}
