import React from "react";
import { Helmet } from "react-helmet";
import { Forms, Hero } from "../layout/apply";
import { Header } from "../layout/blog";
import { Footer } from "../layout/landing";
import icon from '../assets/images/neocedrus.png'
import ogImg from '../assets/images/ogImg.png'


const Apply = () => {
  let url = "https://neocedrus.com/apply" 
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join neoCedrus - Join our team of passionate student developers!</title>
        <meta name="title" content="Join neoCedrus" />
        <meta name="description" content="Join our team of passionate software developers!" />
        <link rel="canonical" href={url} />
        <meta name="image" content="https://cdn.neocedre.com/neocedrus-landing/assets/ogImg.png" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"Join neoCedrus"} />
        <meta property="og:description" content="Student-led Sotware Development Unit at Al Akhawayn University" />
        <meta property="og:image" content="https://cdn.neocedre.com/neocedrus-landing/assets/ogImg.png" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="neoCedrus" />

        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Header></Header>
      <div className="md:px-24">
        <Hero></Hero>
        <Forms></Forms>
      </div>
      <Footer />
    </>
  );
};

export default Apply;
