import { Field } from "formik";
import React, { useEffect } from "react";
import { Countries } from "../../utils";

const PersonalInfo = ({errors, touched, save, values}) => {
  useEffect(() => {
    save(values)
  }, [values, save])
  return (
    <div className="px-4 py-5 bg-white sm:p-6">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First name *
          </label>
          <Field
            type="text"
            name="firstName"
            id="firstName"
            autoComplete="given-name"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.firstName && touched.firstName ? errors.firstName : null }</div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last name *
          </label>
          <Field
            type="text"
            name="lastName"
            id="lastName"
            autoComplete="family-name"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.lastName && touched.lastName ? errors.lastName : null }</div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address (@aui.ma) *
          </label>
          <Field
            type="text"
            name="email"
            id="email"
            autoComplete="email"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.email && touched.email ? errors.email : null }</div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="country"
            className="block text-sm font-medium text-gray-700"
          >
            Country / Region *
          </label>
          <Field as="select"
            id="country"
            name="country"
            autoComplete="country"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <Countries />
          </Field>
          <div className="block text-sm font-medium text-red-600">{errors.country && touched.country ? errors.country : null }</div>
        </div>

        <div className="col-span-6">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700"
          >
            Street address
          </label>
          <Field
            type="text"
            name="address"
            id="address"
            autoComplete="address"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            City *
          </label>
          <Field
            type="text"
            name="city"
            id="city"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.city && touched.city ? errors.city : null }</div>
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="state"
            className="block text-sm font-medium text-gray-700"
          >
            State / Province
          </label>
          <Field
            type="text"
            name="state"
            id="state"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="zip"
            className="block text-sm font-medium text-gray-700"
          >
            ZIP / Postal
          </label>
          <Field
            type="text"
            name="zip"
            id="zip"
            autoComplete="postal-code"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};
export default PersonalInfo;
