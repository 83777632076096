import { Field } from "formik";
import React, { useEffect } from "react";

const SoftwareDevelopment = ({errors, touched, save, values}) => {
  useEffect(() => {
    save(values)
  }, [values, save])
  return (
    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 sm:col-span-2">
          <label
            htmlFor="website"
            className="block text-sm font-medium text-gray-700"
          >
            Include a website portfolio or a Github / Bitbucket / Gitlab Page
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
              @
            </span>
            <Field
              type="text"
              name="website"
              id="website"
              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
              placeholder="https://github.com/tailwind"
            />
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="technologies"
          className="block text-sm font-medium text-gray-700"
        >
          Your Top 5 Technologies *
        </label>
        <div className="mt-1">
          <Field as="textarea"
            id="technologies"
            name="technologies"
            rows={7}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
            placeholder={"- Frameworks\n- Programming Languages\n- Styling\n- Databases\n- CI/CD\n- Etc..."}
          />
          <div className="block text-sm font-medium text-red-600">{errors.technologies && touched.technologies ? errors.technologies : null }</div>
        </div>
        <p className="mt-2 text-sm text-gray-500">
          Feel free to expand in which ever way you want.
        </p>
      </div>
      <div>
        <label
          htmlFor="projects"
          className="block text-sm font-medium text-gray-700"
        >
          Any projects? Talk about your the coolest one in 100-ish words *
        </label>
        <div className="mt-1">
          <Field as="textarea"
            id="projects"
            name="projects"
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.projects && touched.projects ? errors.projects : null }</div>
        </div>
      </div>
      <div>
        <label
          htmlFor="interests"
          className="block text-sm font-medium text-gray-700"
        >
          Are you interested in any project/subject in particular?
        </label>
        <div className="mt-1">
          <Field as="textarea"
            id="interests"
            name="interests"
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevelopment;
