import React from "react";

const Hero = () => {
  return (
    <section>
      <div className="container px-6 py-12 mx-auto">
        <div className="grid items-center gap-4 xl:grid-cols-5">
          <div className="max-w-2xl mx-auto my-8 space-y-4 text-center xl:col-span-2 xl:text-left">
            <p>
            <span className="block lg:text-6xl md:text-4xl text-3xl font-bold text-gray-900">
              Join us at
            </span>
            <span className="font-black lg:text-8xl md:text-6xl text-6xl mb-4 text-gray-900">
              neoCedrus
            </span>
            </p>
            <h2 className="text-coolGray-600 text-xl">
              See why numerous neoCedrus team members recommend applying and
              joining this wonderful environment!
            </h2>
            <a
              href="#form"
              className="whitespace-nowrap mt-3 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary hover:text-gray-900"
            >
              Apply here
            </a>
          </div>
          <div className="p-6 xl:col-span-3">
            <div className="grid gap-4 md:grid-cols-2">
              <div className="grid content-center gap-4">
                <div className="p-6 rounded shadow-md bg-coolGray-50">
                  <p>
                  neoCedrus is the right place for anyone to improve on both a technical level and a personal level! 
                  A safe and fun space to turn your potential into tangible positive impact!
                  </p>
                  <div className="flex items-center mt-4 space-x-4">
                    <img
                      src="https://api.neocedrus.com/uploads/Mohamed_Merrouch_5b6b651611.jpg"
                      alt=""
                      className="w-12 h-12 bg-center bg-cover rounded-full bg-coolGray-500 object-cover"
                    />
                    <div>
                      <p className="text-lg font-semibold">Mohamed Merrouch</p>
                      <p className="text-sm text-coolGray-600">
                        Project Manager
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-6 rounded shadow-md bg-coolGray-50">
                  <p>
                    Being part of neoCedrus allows each one of us to continously learn
                    new things from each other. It also allows us to be involved in 
                    different aspects of product development, from pure coding to working
                    with clients and delivering reliable solutions. 
                  </p>
                  <div className="flex items-center mt-4 space-x-4">
                    <img
                      src="https://api.neocedrus.com/uploads/Imad_Archid_468d17c950.jpg"
                      alt=""
                      className="w-12 h-12 bg-center bg-cover rounded-full bg-coolGray-500 object-cover"
                    />
                    <div>
                      <p className="text-lg font-semibold">Imad Archid</p>
                      <p className="text-sm text-coolGray-600">
                        Project Manager
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid content-center gap-4">
                <div className="p-6 rounded shadow-md bg-coolGray-50">
                  <p>
                  neoCedrus provides a truly uplifting working and learning environment! As soon as I joined, I quickly made friends with everyone and I 
                  started feeling like part of a small community of like-minded people. As a young woman in STEM, I feel lucky to be able to work in 
                  a positive environment with supportive team members.
                  </p>
                  <div className="flex items-center mt-4 space-x-4">
                    <img
                      src="https://api.neocedrus.com/uploads/Leila_Farah_Moussa_526565c51f.jpg"
                      alt=""
                      className="w-12 h-12 bg-center bg-cover rounded-full bg-coolGray-500 object-cover"
                    />
                    <div>
                      <p className="text-lg font-semibold">Leila Farah Moussa</p>
                      <p className="text-sm text-coolGray-600">Software Developer</p>
                    </div>
                  </div>
                </div>
                <div className="p-6 rounded shadow-md bg-coolGray-50">
                  <p>
                  The perfect place to make your first mistakes and to limit test both your soft and hard skills. 
                  Going from the product managers to the fellow software developers, we all have one thing in common: Pursuit of knowledge. 
                  Every project is a completely new adventure for the team where we almost always get out of our comfort zone 
                  to come up with a solution to the problems we face.
                  </p>
                  <div className="flex items-center mt-4 space-x-4">
                    <img
                      src="https://api.neocedrus.com/uploads/Mehdi_Hyani_58f842c047.jpg"
                      alt=""
                      className="w-12 h-12 bg-center bg-cover rounded-full bg-coolGray-500 object-cover"
                    />
                    <div>
                      <p className="text-lg font-semibold">Mehdi Hyani</p>
                      <p className="text-sm text-coolGray-600">
                        Software Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
