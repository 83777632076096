import { Field } from "formik";
import React, { useEffect } from "react";

const Done = ({errors, touched, save, values}) => {
  useEffect(() => {
    save(values)
  }, [values, save])
  return (
    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
      <p className="block text-sm font-medium text-gray-700">
        List 3 time slots of at least 15 minutes within the next 5 business days
        where you're available for a quick chat with us.
      </p>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-3 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="meetingslotone"
            className="block text-sm font-medium text-gray-700"
          >
            Meeting Time #1 *
          </label>
          <Field
            type="datetime-local"
            name="meetingslotone"
            id="meetingslotone"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.meetingslotone && touched.meetingslotone ? errors.meetingslotone : null }</div>
        </div>
        <div className="col-span-3 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="meetingslottwo"
            className="block text-sm font-medium text-gray-700"
          >
            Meeting Time #2 *
          </label>
          <Field
            type="datetime-local"
            name="meetingslottwo"
            id="meetingslottwo"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.meetingslottwo && touched.meetingslottwo ? errors.meetingslottwo : null }</div>
        </div>
        <div className="col-span-3 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="meetingslotthree"
            className="block text-sm font-medium text-gray-700"
          >
            Meeting Time #3 *
          </label>
          <Field
            type="datetime-local"
            name="meetingslotthree"
            id="meetingslotthree"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">{errors.meetingslotthree && touched.meetingslotthree ? errors.meetingslotthree : null }</div>
        </div>
      </div>
      <div>
        <label
          htmlFor="feedback"
          className="block text-sm font-medium text-gray-700"
        >
          Any feedback/comments/suggestions about us, our image, the application
          process, etc.?
        </label>
        <div className="mt-1">
          <Field as="textarea"
            id="feedback"
            name="feedback"
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default Done;
