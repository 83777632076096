import { XIcon } from "@heroicons/react/outline";
import { Field } from "formik";
import React, { useEffect, useState } from "react";

const Academics = ({ file, setFile, errors, touched, save, values }) => {
  const [fileFormatError, setFileFormatError] = useState(false);
  useEffect(() => {
    save(values)
  }, [values, save])
  return (
    <div className="px-4 py-5 bg-white sm:p-6">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="semester"
            className="block text-sm font-medium text-gray-700"
          >
            When did you join AUI? *
          </label>
          <Field
            type="month"
            name="semester"
            id="semester"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">
            {errors.semester && touched.semester ? errors.semester : null}
          </div>
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <label
            htmlFor="major"
            className="block text-sm font-medium text-gray-700"
          >
            Major *
          </label>
          <Field
            type="text"
            name="major"
            id="major"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">
            {errors.major && touched.major ? errors.major : null}
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="minor"
            className="block text-sm font-medium text-gray-700"
          >
            Minor *
          </label>
          <Field
            type="text"
            name="minor"
            id="minor"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">
            {errors.minor && touched.minor ? errors.minor : null}
          </div>
        </div>
      </div>
      <div className="py-5">
        <label className="block text-sm font-medium text-gray-700">
          Unofficial Transcript *
        </label>
        {file ? (
          <div className="flex justify-between">
            <p>{file.name}</p>
            <XIcon className="cursor-pointer" onClick={() => setFile(null)} height={20} width={20} />
          </div>
        ) : (
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="transcript"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Upload your unofficial transcript</span>
                  <Field
                    onChange={(event) => {
                      if (
                        event.target.files[0].name.match(/\.([^\.]+)$/)[1] ===
                        "pdf"
                      ) {
                        setFile(event.target.files[0]);
                        setFileFormatError(false);
                      } else setFileFormatError(true);
                    }}
                    id="transcript"
                    name="transcript"
                    type="file"
                    accept=".pdf"
                    className="sr-only"
                  />
                </label>
              </div>
              {fileFormatError && (
                <p className="text-xs text-red-500">
                  Only PDF is accepted, try again!
                </p>
              )}
              <p className="text-xs font-medium text-gray-500">PDF Format</p>
            </div>
          </div>
        )}
      </div>
      <div>
        <label
          htmlFor="howDidYouHear"
          className="block text-sm font-medium text-gray-700"
        >
          How did you hear about neoCedrus? What made you apply? *
        </label>
        <div className="mt-1">
          <Field
            as="textarea"
            id="howDidYouHear"
            name="howDidYouHear"
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
          <div className="block text-sm font-medium text-red-600">
            {errors.howDidYouHear && touched.howDidYouHear
              ? errors.howDidYouHear
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Academics;
